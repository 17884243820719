import Swiper from "swiper";
import {
	Autoplay,
	EffectFade,
	Grid,
	Navigation,
	Pagination,
	Thumbs,
	Controller,
} from "swiper/modules";
/**
 * @param swiperInit
 */
export default function swiperInit() {
	var homeBanner = new Swiper(`.home-banner .swiper`, {
		modules: [Navigation, Pagination, Autoplay],
		loop: true,
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
		},
		navigation: {
			nextEl: ".home-banner .btn-next",
			prevEl: ".home-banner .btn-prev",
		},
		pagination: {
			el: `.home-banner .swiper-pagination`,
			dynamicBullets: true,
		},
	});
	var pageBanner = new Swiper(`.page-banner .swiper`, {
		modules: [Navigation, Pagination],
		slidesPerView: 1,
		// autoplay: {
		// 	delay: 5000,
		// },
		navigation: {
			nextEl: ".page-banner .btn-next",
			prevEl: ".page-banner .btn-prev",
		},
		pagination: {
			el: `.page-banner .swiper-pagination`,
			dynamicBullets: true,
		},
	});
	var home3 = new Swiper(`.home-3 .swiper`, {
		modules: [Navigation, Pagination, Grid],
		grid: {
			fill: "row",
			rows: 1,
		},
		slidesPerView: 1,
		spaceBetween: 16,
		// autoplay: {
		// 	delay: 5000,
		// },
		navigation: {
			nextEl: ".home-3 .btn-next",
			prevEl: ".home-3 .btn-prev",
		},
		pagination: {
			el: `.home-3 .swiper-pagination`,
			dynamicBullets: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1280: {
				grid: {
					rows: 2,
				},
				slidesPerView: 3,
				spaceBetween: 32,
			},
		},
	});

	var home4 = new Swiper(`.home-4 .swiper`, {
		modules: [Navigation, Pagination, Grid],
		slidesPerView: 2,
		spaceBetween: 16,
		// autoplay: {
		// 	delay: 5000,
		// },
		navigation: {
			nextEl: ".home-4 .btn-next",
			prevEl: ".home-4 .btn-prev",
		},
		pagination: {
			el: `.home-4 .swiper-pagination`,
			dynamicBullets: true,
		},
		breakpoints: {
			768: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 6,
				spaceBetween: 32,
			},
		},
	});

	const swiper1Col = $(".swiper-1-col");
	swiper1Col.each(function (index, item) {
		$(item).addClass(`swiper-1-col-${index}`);

		new Swiper(`.swiper-1-col-${index} .swiper`, {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			// autoplay: {
			// 	delay: 5000,
			// },
			pagination: {
				el: `.swiper-1-col-${index} .swiper-pagination`,
				dynamicBullets: true,
			},
			navigation: {
				nextEl: `.swiper-1-col-${index} .btn-next`,
				prevEl: `.swiper-1-col-${index} .btn-prev`,
			},
		});
	});

	const swiper2Col = $(".swiper-2-col");
	swiper2Col.each(function (index, item) {
		$(item).addClass(`swiper-2-col-${index}`);

		new Swiper(`.swiper-2-col-${index} .swiper`, {
			modules: [Navigation, Pagination],
			slidesPerView: 2,
			spaceBetween: 8,
			// autoplay: {
			// 	delay: 5000,
			// },
			pagination: {
				el: `.swiper-2-col-${index} .swiper-pagination`,
				dynamicBullets: true,
			},
			navigation: {
				nextEl: `.swiper-2-col-${index} .btn-next`,
				prevEl: `.swiper-2-col-${index} .btn-prev`,
			},
			breakpoints: {
				576: {
					slidesPerView: 2,
					spaceBetween: 16,
				},
				1280: {
					slidesPerView: 2,
					spaceBetween: 32,
				},
			},
		});
	});

	const swiper3Col = $(".swiper-3-col");
	swiper3Col.each(function (index, item) {
		$(item).addClass(`swiper-3-col-${index}`);

		new Swiper(`.swiper-3-col-${index} .swiper`, {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			spaceBetween: 16,
			// autoplay: {
			// 	delay: 5000,
			// },
			pagination: {
				el: `.swiper-3-col-${index} .swiper-pagination`,
				dynamicBullets: true,
			},
			navigation: {
				nextEl: `.swiper-3-col-${index} .btn-next`,
				prevEl: `.swiper-3-col-${index} .btn-prev`,
			},
			breakpoints: {
				576: {
					slidesPerView: 2,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 16,
				},
				1280: {
					spaceBetween: 32,
					slidesPerView: 3,
				},
			},
		});
	});

	const swiper4Col = $(".swiper-4-col");
	swiper4Col.each(function (index, item) {
		$(item).addClass(`swiper-4-col-${index}`);
		// console.log($(`.swiper-4-col-${index}`));

		new Swiper(`.swiper-4-col-${index} .swiper`, {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			spaceBetween: 16,
			// autoplay: {
			// 	delay: 5000,
			// },
			navigation: {
				nextEl: `.swiper-4-col-${index} .btn-next`,
				prevEl: `.swiper-4-col-${index} .btn-prev`,
			},
			pagination: {
				el: `.swiper-4-col-${index} .swiper-pagination`,
				dynamicBullets: true,
			},
			breakpoints: {
				576: {
					slidesPerView: 2,
				},
				768: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
					spaceBetween: 32,
				},
			},
		});
	});

	var projectEquipmentWrapper = new Swiper(
		`.project-equipment .swiper-block-wrapper > .swiper`,
		{
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			// autoplay: {
			// 	delay: 5000,
			// },
			navigation: {
				nextEl: ".project-equipment .button-main .btn-next",
				prevEl: ".project-equipment .button-main .btn-prev",
			},
			pagination: {
				el: `.project-equipment .swiper-block-wrapper .swiper-pagination`,
				dynamicBullets: true,
			},
		}
	);

	$(".project-equipment .swiper-inner").each(function (index, item) {
		$(item).addClass(`swiper-inner-${index}`);

		var projectEquipmentInner = new Swiper(
			`.project-equipment .swiper-inner-${index} .swiper`,
			{
				modules: [Navigation, Pagination],
				slidesPerView: 1,
				// autoplay: {
				// 	delay: 5000,
				// },
				navigation: {
					nextEl: `.project-equipment .swiper-inner-${index} .btn-next`,
					prevEl: `.project-equipment .swiper-inner-${index} .btn-prev`,
				},
				pagination: {
					el: `.project-equipment .swiper-inner-${index} .swiper-pagination`,
					dynamicBullets: true,
				},
			}
		);
	});
}

