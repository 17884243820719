import AOS from "aos";
import LazyLoad from "vanilla-lazyload";
import {
	setBackgroundDesktopElement,
	detectCloseElement,
	buttonToTop,
	clickScrollToDiv,
	appendCaptchaASP,
	clickScroll,
} from "./helper";
import { header } from "./header";
import swiperInit, { homeBanner } from "./swiper";
import { clickLoadMoreAjax } from "./utils";
import { CountUp } from "countup.js";
$(document).ready(function () {
	setBackgroundDesktopElement();
	header.init();
	swiperInit();
	appendCaptchaASP();
	appendBreadcrumb();
	SelectSortProvider();
	buttonToTop();
	clickLoadMoreAjax();
	handleReport();
	// clickScroll();

	// Custom
	handleCountNumber();
	handleMenuMobileDropdown();
	handleBackgroundHome();
	handleShareSocial();

	window.swiperInit = swiperInit;

	/*==================== Aos Init ====================*/
	AOS.init();
	var lazyLoadInstance = new LazyLoad({
		// Your custom settings go here
	});
	window.lazyLoadInstance = lazyLoadInstance;
});

function handleReport() {
	if (!$(".section-infomation-disclosure").length) return;
	console.log("Runn");
	$(".swiper-slide-active a").trigger("click");
}
/*==================== SelectSortProvider ====================*/
function SelectSortProvider() {
	function processAjax(url, nodeElement) {
		$.ajax({
			url: url,
			beforeSend: function (data) {
				$(".loading").addClass("active");
			},
			success: async function (data) {
				$(".ajaxResponse").html($(data).find(".ajaxResponse").html());
				if ($(".pages.newspager").length) {
					if ($(data).find(".pages").length) {
						$(".pages.newspager").html(
							$(data).find(".pages.newspager").html()
						);
					} else {
						$(".pages").html("");
					}
				} else {
					$(".ajaxResponse").append(
						$(data).find(".pages.newspager").html()
					);
				}
				$(".loading").removeClass("active");
				observer.observe();
			},
		});
	}
	$(".tab-select-provider select").on("change", function () {
		let value = $(this).val();
		console.log("🚀 ~ file: main.js:47 ~ value:", value);
		processAjax(value);
	});
	let ajaxTimeoutId;
	$(".ajaxLink").on("click", function (e) {
		e.preventDefault();
		let value = $(this).attr("href");
		debounceAjax(value);
		return false;
	});
	function debounceAjax(value) {
		if (ajaxTimeoutId) {
			clearTimeout(ajaxTimeoutId); // clear the previous timeout if it exists
		}
		ajaxTimeoutId = setTimeout(() => {
			processAjax(value); // call the processAjax() function after a debounce
		}, 500); // set a debounce timeout of 500ms (adjust the time as needed)
	}
}

/*==================== Append Breadcrumb ====================*/

function appendBreadcrumb() {
	if (!$(".page-banner-main").length) return;
	$(".global-breadcrumb").appendTo(
		".page-banner-main .wrap-global-breadcrumb"
	);
}

const observerPhase = new IntersectionObserver(
	(entries, observe) => {
		entries.forEach((entry) => {});
	},
	{
		threshold: [1],
		rootMargin: "0px",
	}
);
const nodeElementsTEst = document.querySelectorAll(
	".wrap-item-phase .text-phase-main"
);
nodeElementsTEst.forEach((ele) => {
	observerPhase.observe(ele);
});

const nodeElements = document.querySelectorAll(".text-phase-main");

window.addEventListener("scroll", function () {
	nodeElements.forEach((nodeElement) => {
		const rect = nodeElement.getBoundingClientRect();
		const position = rect.top + window.scrollY;
		const viewportTop = window.scrollY;
		const threshold = 200;
		const namePhase = $(nodeElement).data("phase");
		if (position <= viewportTop + threshold) {
			nodeElement.classList.add("active");
			$(`a[href="#${namePhase}"]`)
				.addClass("active-scroll")
				.parent()
				.siblings()
				.find("a")
				.removeClass("active-scroll");
			$(".wrap-phase-mobile .wrap-select select").val(namePhase).change();
		} else {
			$(`a[href="#${namePhase}"]`).removeClass("activ-scroll");
			nodeElement.classList.remove("active");
		}
	});
});

function handleCountNumber() {
	const couters = document.querySelectorAll(".counter");

	couters.forEach(function (item) {
		let demo = new CountUp(item, item.dataset.count, {
			separator: ".",
			enableScrollSpy: true,
		});
		if (!demo.error) {
			demo.start();
		} else {
			console.error(demo.error);
		}
	});
}

function handleMenuMobileDropdown() {
	$(".header-menu-mobile .menu-item-has-children .icon").on(
		"click",
		function () {
			$(this)
				.closest("li")
				.toggleClass("show-child")
				.find("ul")
				.slideToggle();
		}
	);
}

function handleBackgroundHome() {
	const container = $(".home-2 .home-2-content");
	const containerBackground = container.data("bg");
	const items = container.find(".home-2-item");

	items.each(function () {
		$(this).on("mouseenter", function () {
			let srcImage = $(this).data("src");
			container.css("background-image", `url(${srcImage})`);
		});
	});

	container.on("mouseenter", function () {
		$(this).addClass("active");
	});
	container.on("mouseleave", function () {
		container.css("background-image", `url(${containerBackground})`);
		$(this).removeClass("active");
	});
}

function handleShareSocial() {
	var puWidth = 600;
	var puHeight = 400;

	var shareOptions = {
		width: puWidth,
		height: puHeight,
		left: screen.width / 2 - puWidth / 2,
		top: screen.height / 2 - puHeight / 2,
	};
	const Features =
		"width=" +
		shareOptions.width +
		",height=" +
		shareOptions.height +
		",left=" +
		shareOptions.left +
		",top=" +
		shareOptions.top;
	$("#facebook-share").on("click", function (e) {
		e.preventDefault();
		const fbShareUrl =
			"https://www.facebook.com/sharer/sharer.php?u=<?php the_permalink(); ?>&title=" +
			encodeURIComponent("<?php echo get_the_title(); ?>") +
			"&description=" +
			encodeURIComponent("<?php echo get_the_excerpt(); ?>");
		window.open(fbShareUrl, "_blank", Features);
	});
	$("#twitter-share").on("click", function (e) {
		e.preventDefault();
		const twShareUrl =
			"http://twitter.com/share?text=" +
			encodeURIComponent("<?= the_title() ?>") +
			"&url=" +
			encodeURIComponent("<?= the_permalink() ?>");
		window.open(twShareUrl, "_blank", Features);
	});
	$("#linkedin-share").on("click", function (e) {
		e.preventDefault();
		const linkedinShareUrl =
			"https://www.linkedin.com/sharing/share-offsite/?url=" +
			encodeURIComponent("<?= the_permalink() ?>");
		window.open(linkedinShareUrl, "_blank", Features);
	});
}
